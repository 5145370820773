.card-span{
  transition: all 0.4s ease;
  border:0;
  .card-span-img{
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}
// .card-bg {
//   background-color: rgba($white, 0.2);
//   backdrop-filter: blur(0.625rem);
//   border: 0.125rem solid rgba($white, 0.3);

// }
// .product-card-bg {
//   background-color: rgba($white, 0.2);
//   backdrop-filter: blur(1.875rem);
//   border: 0.125rem solid $white;
//   border-radius: 5.375rem;
// }

// .project-card-bg{
//   background-color: rgba($white, 0.2);
//   backdrop-filter: blur(0.625rem);
//   border: 0.125rem solid $white;
//   border-radius: 1.6875rem;
// }

// .testimonial-card-bg {
//   background-color: rgba($white, 0.2);
//   backdrop-filter: blur(1.875rem);
//   border: 0.125rem solid $white;
//   border-radius: 7.5rem;
// }

// .team-card {
//   background-color: rgba($white, 0.2);
  
//   &-1 {
//     border-radius:  1.5rem 2.5rem 1.5rem 4.375rem;
//     border: 0.125rem solid rgba($white, 0.9);
//   }
//   &-2 {
//     border-radius: 1.5rem 4.375rem 1.5rem 2.5rem;
//     border: 0.125rem solid rgba($white, 0.9);
//   }
//   &-3 {
//     border-radius: 4.375rem 2.5rem 1.5rem 1.5rem;
//     border: 0.125rem solid rgba($white, 0.9);
//   }
//   &-4 {
//     border-radius: 1.5rem 1.5rem 4.375rem 2.5rem;
//     border: 0.125rem solid rgba($white, 0.9);
//   }
// }
