/*-----------------------------------------------
|   Backgrounds
-----------------------------------------------*/
.bg-holder {
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  will-change: transform, opacity, filter;
  backface-visibility: hidden;
  background-repeat: no-repeat;
  z-index: 0;

  &.bg-right {
    left: auto;
    right: 0;
  }

  &.overlay:before {
    position: absolute;
    content: "";
    background: rgba(#000, 0.25);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &.overlay-0:before {
    background: rgba(#000, 0.7);
  }
  &.overlay-1:before {
    background: rgba(#000, 0.55);
  }
  &.overlay-2:before {
    background: rgba(#000, 0.4);
  }

  .bg-video {
    position: absolute;
    display: block;
    z-index: -1;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    min-width: 100%;
  }

  .bg-youtube {
    position: absolute !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

//- testimonial background

.bg-primary-gradient{
  background: linear-gradient(90deg,rgba(48, 17, 188, 1), rgba(126, 17, 188, 1));
}

.bg-secondary-gradient{
  background: linear-gradient(90deg,rgba(254, 190, 21, 1), rgba(254, 122, 21, 1));
}
.bg-voyage-gradient{
  background: linear-gradient(180deg, rgba(57, 67, 77, 0) 0%, #39424d79 100%);
}

.bg-offcanvas{
  position: relative;
    &::before {
      position: absolute;
      background: #3011bc;
      content: "";
      height: 100%;
      width: 1000%;
      margin-left: -1000%;
  }
}

.bg-offcanvas-right{
  position: relative;
    &::before {
      position: absolute;
      // background: #3011bc;
      background: linear-gradient(90deg,rgba(126, 17, 188, 1), rgba(126, 17, 188, 1));
      content: "";
      height: 100%;
      width: 1000%;
      margin-right: -1000%;
      z-index: -1;
  }
}
.bg-offcanvas-gray{
  position: relative;
    &::before {
      position: absolute;
      background: #F5F3FC;
      content: "";
      height: 100%;
      width: 1000%;
      margin-left: -1000%;
  }
}
.bg-offcanvas-gray-right{
  position: relative;
    &::before {
      position: absolute;
      background: #F5F3FC;
      // background: linear-gradient(90deg,rgba(126, 17, 188, 1), rgba(126, 17, 188, 1));
      content: "";
      height: 100%;
      width: 1000%;
      margin-right: -1000%;
      z-index: -1;
  }
}