// navbars

.border-navbar-gradient{ 
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.890625) 10.94%, #FFFFFF 86.46%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(60px);
}

.backdrop{
  backdrop-filter: blur(4px);
}


.nav-link-icon {
  width: 1.3rem;
  min-width: 1.5rem;
}

.nav-link-text{
  color: $gray-800;
  word-break: break-word;
  font-weight: bold;
  transition: all .2s ease-in-out;
}