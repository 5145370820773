// Tabs
//


.voyage-tabs {
  border-bottom:0;

  .nav-link {
    border: 2px solid transparent;
    color:$gray-800;
    &:hover,
    &:focus {
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    border-bottom-color: $nav-tabs-link-active-color;
  }
}